import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import ScrollProgressRead from 'react-scroll-progress-read';
import { useLocation } from '@reach/router';
import Headroom from 'react-headroom';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import Navbar from './Navbar';
import SEO from './SEO';

import florianGyger from '../assets/images/florian-gyger.jpg';
import '../assets/sass/stylish-portfolio.scss';
const ReadingProgressBar = () => (
  <div
    style={{
      position: 'fixed',
      zIndex: 9999,
    }}
  >
    <ScrollProgressRead
      backgroundColor="#dee2e6"
      barColor="#8aa989"
      height="3px"
    />
  </div>
);

const cookieConsentElement = (
  <CookieConsent
    buttonText="OK, I understand"
    cookieName="user-accepted-cookies"
  >
    As described in the{' '}
    <Link to="/data-privacy" title="Data privacy statement">
      data privacy statement
    </Link>
    , this website uses cookies to ensure the best possible functionality to the
    visitors. By using this website you agree with that.
  </CookieConsent>
);

const Layout = props => {
  const { href: currentUrl } = useLocation();

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              languageCode
              siteName
            }
          }
        }
      `}
      render={data => (
        <div className="layout">
          <SEO
            title={data.site.siteMetadata.title}
            description={data.site.siteMetadata.description}
            image={florianGyger}
            meta={[
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: currentUrl,
              },
              {
                property: `og:locale`,
                content: `en_US`,
              },
              {
                property: `og:site_name`,
                content: data.site.siteMetadata.siteName,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
            ]}
          >
            <html lang={data.site.siteMetadata.languageCode} />
          </SEO>
          {cookieConsentElement}
          <div className="page-top">
            {props.displayReadingProgressBar && <ReadingProgressBar />}
            {!props.hideNavbar && (
              <Headroom style={{ zIndex: 999 }}>
                <Navbar />
              </Headroom>
            )}
            <div
              style={{
                paddingBottom: '5rem',
              }}
            >
              {props.children}
            </div>
            <ScrollToTop />
            <Footer />
          </div>
        </div>
      )}
    />
  );
};

export default Layout;
