import React from 'react';
import Helmet from 'react-helmet';

const SEO = props => {
  const {
    title,
    description,
    image,
    meta: existingMeta,
    children,
    ...otherProps
  } = props;

  let properties = {
    ...(otherProps && otherProps),
  };
  let meta = existingMeta ? [...existingMeta] : [];

  if (title) {
    properties = {
      ...properties,
      title,
    };
    meta = [
      ...meta,
      {
        property: `og:title`,
        content: title,
      },
      {
        name: `twitter:title`,
        content: title,
      },
    ];
  }

  if (description) {
    meta = [
      ...meta,
      {
        name: 'description',
        content: description,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        name: `twitter:description`,
        content: description,
      },
    ];
  }

  if (image) {
    meta = [
      ...meta,
      {
        property: `og:image`,
        content: image,
      },
      {
        name: `twitter:image`,
        content: image,
      },
    ];
  }

  return (
    <Helmet {...properties} meta={meta}>
      {children}
    </Helmet>
  );
};

export default SEO;
