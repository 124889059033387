import React from 'react';
import { Link } from 'gatsby';

export default function Footer() {
  return (
    <footer className="footer text-center">
      <div className="container">
        <div className="row mb-3 w-50 mx-auto">
          <div className="col-md-4 mb-2">
            <Link to="/" title="Home page of this website">
              Home
            </Link>
          </div>
          <div className="col-md-4 mb-2">
            <Link to="/impressum" title="Impressum">
              Impressum
            </Link>
          </div>
          <div className="col-md-4 mb-2">
            <Link to="/data-privacy" title="Data privacy statement">
              Data privacy
            </Link>
          </div>
        </div>
        <p className="text-muted small mb-0">
          Copyright &copy;{' '}
          <a
            href="https://floriangyger.ch"
            target="_blank"
            title="Website of Florian Gyger Software"
          >
            Florian Gyger Software
          </a>
        </p>
      </div>
    </footer>
  );
}
