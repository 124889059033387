import React from 'react';
import { Link } from 'gatsby';
import { Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';

const Navbar = () => {
  return (
    <BootstrapNavbar variant="dark" bg="dark" expand="sm">
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/" title="Home page of this website">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="/mailing-list"
              title="Mailing list signup form"
            >
              Newsletter
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="/blog"
              title="Overview of all blog posts"
            >
              Blog
            </Link>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="mailto:florian.gyger@floriangyger.ch"
              title="Write an email to Florian Gyger"
            >
              Contact
            </a>
          </li>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default Navbar;
